@use "variables" as vars;

.footer {
    background-color: map-get(vars.$colors, "orange");
    padding: 0.5rem 0;
    & .footer-text-menu {
        display: flex;
        justify-content: center;
        & .footer-text-menu-item {
            margin: 0 1rem;
            color: mix(
                map-get(vars.$colors, "orange"),
                map-get(vars.$colors, "white"),
                25%
            );
            text-decoration: none;
            &:hover,
            &:focus {
                color: map-get(vars.$colors, "white");
            }
        }
    }
}
