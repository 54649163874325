@use "sass:math";
@use "variables" as vars;

html {
    background-color: map-get(vars.$colors, "white");
    font-family: Fira Sans, Arial, sans-serif;
    & .leaflet-container {
        font-family: Fira Sans, Arial, sans-serif;
    }
}

.container {
    position: relative;
    padding: 0 2rem;
    max-width: 900px;
    margin: 0 auto;
}

@for $i from 1 through 2 {
    h#{$i} {
        position: relative;
        display: inline-block;
        z-index: 1;
        color: map-get(vars.$colors, "orange");
        margin: 0;
        font-weight: 500;
        @each $bp_name, $bp_value in vars.$breakpoints {
            @media (min-width: $bp_value) {
                font-size: map-get(vars.$fontsizes, "h#{$i}", $bp_name);
            }
        }
    }
}

p {
    font-weight: 300;
    line-height: 1.3;
}

li {
    font-weight: 300;
}

a {
    color: map-get(vars.$colors, "orange");
    text-decoration: none;
    &:hover,
    &:focus {
        color: map-get(vars.$colors, "red");
    }
}

button {
    cursor: pointer;
    &.button-speaker {
        background: none;
        border: none;
        padding: 0;
        transition: transform 0.1s ease-out;
        &:hover,
        &:focus {
            transform: scale(1.2);
        }
        &.playing {
            & svg {
                fill: map-get(vars.$colors, "red");
            }
        }
        & svg {
            display: block;
            fill: map-get(vars.$colors, "orange");
        }
    }
}

.anchor-button-wrapper {
    text-align: center;
    & a.anchor-button {
        cursor: pointer;
        display: inline-block;
        padding: 0.25rem 1.5rem;
        margin: 0 auto;
        background-color: map-get(vars.$colors, "orange");
        color: map-get(vars.$colors, "white");
        border-radius: 0.25rem;
        text-decoration: none;
        &:hover,
        &:focus {
            box-shadow: 0 0 2px 2px rgba(map-get(vars.$colors, "orange"), 0.5);
        }
    }
}

.map-key-wrapper {
    text-align: center;
    margin: 1rem 0;
    & .map-key-list {
        display: inline-block;
        list-style-type: none;
        padding-left: 0;
        text-align: left;
        margin: 0;
        & .map-key-list-item {
            display: inline-flex;
            margin-right: 1rem;
            & .map-key-image-wrapper {
                display: inline-block;
                svg {
                    height: 1.2rem;
                    width: auto;
                    display: block;
                }
            }
            & .map-key-label {
                display: inline-block;
                margin: 0 0 0 0.5rem;
            }
        }
    }
}
