/* fira-sans-300 - latin */
@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/fira-sans-v11-latin-300.eot");
    src: local(""),
        url("../fonts/fira-sans-v11-latin-300.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/fira-sans-v11-latin-300.woff2") format("woff2"),
        url("../fonts/fira-sans-v11-latin-300.woff") format("woff"),
        url("../fonts/fira-sans-v11-latin-300.ttf") format("truetype"),
        url("../fonts/fira-sans-v11-latin-300.svg#FiraSans") format("svg");
}

/* fira-sans-regular - latin */
@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/fira-sans-v11-latin-regular.eot");
    src: local(""),
        url("../fonts/fira-sans-v11-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/fira-sans-v11-latin-regular.woff2") format("woff2"),
        url("../fonts/fira-sans-v11-latin-regular.woff") format("woff"),
        url("../fonts/fira-sans-v11-latin-regular.ttf") format("truetype"),
        url("../fonts/fira-sans-v11-latin-regular.svg#FiraSans") format("svg");
}

/* fira-sans-500 - latin */
@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/fira-sans-v11-latin-500.eot");
    src: local(""),
        url("../fonts/fira-sans-v11-latin-500.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/fira-sans-v11-latin-500.woff2") format("woff2"),
        url("../fonts/fira-sans-v11-latin-500.woff") format("woff"),
        url("../fonts/fira-sans-v11-latin-500.ttf") format("truetype"),
        url("../fonts/fira-sans-v11-latin-500.svg#FiraSans") format("svg");
}

/* fira-sans-600 - latin */
@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/fira-sans-v11-latin-600.eot");
    src: local(""),
        url("../fonts/fira-sans-v11-latin-600.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/fira-sans-v11-latin-600.woff2") format("woff2"),
        url("../fonts/fira-sans-v11-latin-600.woff") format("woff"),
        url("../fonts/fira-sans-v11-latin-600.ttf") format("truetype"),
        url("../fonts/fira-sans-v11-latin-600.svg#FiraSans") format("svg");
}
