@use "variables" as vars;

a.skip-to-main-content {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    text-decoration: none;
    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        padding: 5px 20px;
        z-index: 1001;
        border: 2px solid map-get(vars.$colors, "orange");
        background-color: map-get(vars.$colors, "white");
        color: map-get(vars.$colors, "orange");
    }
}

.page-overlay {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(map-get(vars.$colors, "black"), 0.5);
    &.hidden {
        display: none;
    }
    @media (min-width: map-get(vars.$breakpoints, "md")) {
        display: none;
    }
}

header {
    &.fixed {
        position: fixed;
        z-index: 15;
        left: 0;
        width: 100%;
    }
    &:not(.fixed) + .header-placeholder {
        display: none;
    }
    &.fixed + .header-placeholder + .scroll-to-top-wrapper {
        display: none;
    }
    .header-top {
        background-color: map-get(vars.$colors, "orange");
        & .header-top-inner {
            padding: 0;
            // padding: 1rem 0;
            & .header-image-wrapper {
                // & svg {
                & img {
                    width: 100%;
                    height: auto;
                }
            }
            & .header-title-wrapper {
                padding: 0.5rem 2rem 0 2rem;
                color: map-get(vars.$colors, "white");
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                flex-wrap: wrap;
                & .header-title {
                    font-size: 2.5rem;
                    font-weight: 600;
                }
                & .header-subtitle {
                    font-size: 1rem;
                    margin-bottom: 0.25rem;
                }
            }
        }
    }

    .header-bottom {
        background-color: map-get(vars.$colors, "purple");
        & .header-navigation {
            position: fixed;
            z-index: 100;
            right: 0;
            top: 0;
            height: 100%;
            background-color: map-get(vars.$colors, "orange");
            transition: width 0.25s ease-in;
            @media (min-width: map-get(vars.$breakpoints, "md")) {
                position: relative;
                right: auto;
                top: auto;
                height: auto;
                background-color: transparent;
            }
            &.hidden {
                width: 0;
                @media (min-width: map-get(vars.$breakpoints, "md")) {
                    width: auto;
                }
                & .navigation-close-button {
                    display: none;
                }
            }
            & .navigation-close-button {
                position: absolute;
                top: 2rem;
                right: 2rem;
                cursor: pointer;
                font-size: 3rem;
                color: map-get(vars.$colors, "light-purple");
                &:hover,
                &:focus {
                    color: map-get(vars.$colors, "white");
                }
                @media (min-width: map-get(vars.$breakpoints, "md")) {
                    display: none;
                }
            }
            & .header-navigation-list {
                margin: 6rem 2rem 0 2rem;
                padding: 0.5rem 0;
                list-style-type: none;
                & .header-navigation-list-item {
                    text-align: right;
                    margin: 1rem 0;
                    font-size: 1.5rem;
                    & a {
                        color: mix(
                            map-get(vars.$colors, "orange"),
                            map-get(vars.$colors, "white")
                        );
                        &:hover,
                        &:focus {
                            color: map-get(vars.$colors, "white");
                        }
                        &.current-page {
                            text-transform: uppercase;
                            color: map-get(vars.$colors, "white");
                            font-weight: 600;
                        }
                    }
                }
                @media (min-width: map-get(vars.$breakpoints, "md")) {
                    margin: 0;
                    display: flex;
                    justify-content: space-between;
                    & .header-navigation-list-item {
                        margin: 0;
                        padding: 0.25rem 0;
                        font-size: 1.25rem;
                        & a {
                            color: map-get(vars.$colors, "light-purple");
                        }
                    }
                }
            }
        }
        & .navigation-button {
            cursor: pointer;
            & svg {
                width: 36px;
                height: 36px;
                fill: map-get(vars.$colors, "light-purple");
            }
            &:hover {
                & svg {
                    fill: map-get(vars.$colors, "white");
                }
            }
            @media (min-width: map-get(vars.$breakpoints, "md")) {
                display: none;
            }
        }
    }
}

.header-placeholder {
    height: 300px;
}

.scroll-to-top-wrapper {
    &.hidden {
        display: none;
    }
    position: fixed;
    z-index: 30;
    width: 100%;
    bottom: 1rem;
    text-align: right;
    & .scroll-to-top {
        position: relative;
        display: inline-block;
        margin-right: 1rem;
        width: 2rem;
        height: 2rem;
        background-color: map-get(vars.$colors, "white");
        font-size: 2rem;
        border: 2px solid
            mix(map-get(vars.$colors, "orange"), map-get(vars.$colors, "white"));
        border-radius: 50%;
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            left: 0.4375rem;
            top: 0.625rem;
            width: 0.75rem;
            height: 0.75rem;
            border: 2px solid
                mix(
                    map-get(vars.$colors, "orange"),
                    map-get(vars.$colors, "white")
                );
            transform-origin: 50% 50%;
            transform: rotate(45deg);
            border-right: none;
            border-bottom: none;
        }
        &:hover,
        &:focus,
        &:hover:before,
        &:focus:before {
            border-color: map-get(vars.$colors, "orange");
        }
    }
}
